// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-contacto-ok-js": () => import("./../../src/pages/contacto-ok.js" /* webpackChunkName: "component---src-pages-contacto-ok-js" */),
  "component---src-pages-gestion-tiempo-js": () => import("./../../src/pages/gestion-tiempo.js" /* webpackChunkName: "component---src-pages-gestion-tiempo-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informix-js": () => import("./../../src/pages/informix.js" /* webpackChunkName: "component---src-pages-informix-js" */),
  "component---src-pages-kit-digital-js": () => import("./../../src/pages/kit-digital.js" /* webpackChunkName: "component---src-pages-kit-digital-js" */),
  "component---src-pages-politica-cookies-js": () => import("./../../src/pages/politica-cookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-soporte-informatico-js": () => import("./../../src/pages/soporte-informatico.js" /* webpackChunkName: "component---src-pages-soporte-informatico-js" */),
  "component---src-templates-blog-list-category-js": () => import("./../../src/templates/blog-list-category.js" /* webpackChunkName: "component---src-templates-blog-list-category-js" */),
  "component---src-templates-blog-list-post-js": () => import("./../../src/templates/blog-list-post.js" /* webpackChunkName: "component---src-templates-blog-list-post-js" */),
  "component---src-templates-blog-list-tag-js": () => import("./../../src/templates/blog-list-tag.js" /* webpackChunkName: "component---src-templates-blog-list-tag-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

