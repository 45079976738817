module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.serviciosinformaticos.net"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-58Q2J3H","defaultDataLayer":{"type":"function","value":"function () {\n\t\t\treturn {\n\t\t\t  pageType: window.pageType,\n\t\t\t}\n\t\t  }"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-7323120-2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DMS Mantenimiento informático Barcelona","short_name":"DMS","description":"Mantenimiento Informático Barcelona, Servicios Informáticos, Outsourcing, Planificación de personal, Soporte, IBM Informix, Hosting","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/icon.png","icon_options":{"purpose":"maskable any"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9b60a5f29621417e19de42694377018b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
